import React from 'react';
import PropTypes from 'prop-types';
import Config from '../../config';
import { withNamespaces } from 'react-i18next';
import { AppContext } from '../../context/global';
import ModalPitch from './ModalPitch';
import WaitDownloadLink from '../../common/components/WaitDownloadLink';
import Events from './Events';
import ExportToDocx from '../../documents/components/ExportToDocx';

class DownloadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPitch: false,
      hideButton: false,
    };
    this.onAfterDownloadStart = this.onAfterDownloadStart.bind(this);
  }

  componentDidMount() {
    this.props.editor.on(Events.BEFORE_SAVE, () => {
      this.setState((s) => ({ ...s, hideButton: true }));
    });

    this.props.editor.on(Events.AFTER_SAVE, (evt) => {
      if (!evt.data || !evt.data.will_compile) {
        this.setState((s) => ({ ...s, hideButton: false }));
      }
    });

    this.props.editor.on(Events.AFTER_COMPILE, () => {
      if (!this.state.hideButton) {
        this.setState((s) => ({ ...s, hideButton: true }));
      }
    });

    this.props.editor.on(Events.AFTER_COMPILATION_COMPLETE, () => {
      this.setState((s) => ({ ...s, hideButton: false }));
    });
  }

  onAfterDownloadStart() {
    // If has no plan, show pitch modal
    this.setState((state) => ({
      ...state,
      showPitch: !this.context.has_valid_paid_plan,
    }));
  }

  render() {
    if (!this.props.document || this.state.hideButton) {
      return null;
    }

    const { i18n } = this.props;

    return (
      <>
        <li id="download-pdf" title={i18n.t('Baixar documento em PDF')} className="dropdown">
          <a data-toggle="dropdown" role="button" aria-expanded="false" className="dropdown-toggle">
            <span className="icon mdi mdi-download" /> Baixar
          </a>

          <ul className="dropdown-menu" style={{ right: 'unset' }}>
            <li>
              <WaitDownloadLink
                buttonTitle={i18n.t('Baixar PDF do documento')}
                // hideLabel={true}
                buttonIcon="icon far fa-file-pdf"
                href={`${Config.apiHost}documents/${this.props.document.id}/download_pdf/`}
                onAfterDownloadStart={this.onAfterDownloadStart}
                errorMessage={i18n.t(
                  'Não possível baixar o PDF do documento. Salve o documento e tente novamente. Se o error persistir, por favor, contate nosso suporte.',
                )}
              />
            </li>
            <li>
              <ExportToDocx />
            </li>
          </ul>
        </li>

        <ModalPitch
          show={this.state.showPitch}
          onClose={() => this.setState((state) => ({ ...state, showPitch: false }))}
        />
      </>
    );
  }
}

DownloadButton.contextType = AppContext;

DownloadButton.propTypes = {
  document: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
};

export default withNamespaces()(DownloadButton);
