import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from '../../common/containers/Modal';
import Videos from '../../help/Videos';
import { withNamespaces } from 'react-i18next';
import URLS from '../../urls';
import ExportToDocx from '../../documents/components/ExportToDocx';

class HelpButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalVideos: false,
      showModalAssessoria: false,
    };
  }

  componentDidMount() {
    window.solicitarAssessoria = () => {
      this.setState((state) => ({ ...state, showModalAssessoria: true }));
    };
  }

  componentWillUnmount() {
    delete window.solicitarAssessoria;
  }

  render() {
    const { i18n } = this.props;
    return (
      <React.Fragment>
        <li title={i18n.t('Obter ajuda')} className="dropdown">
          <a data-toggle="dropdown" role="button" aria-expanded="false" className="dropdown-toggle">
            <span className="icon mdi mdi-help" />
          </a>
          <ul className="dropdown-menu">
            <li>
              <ExportToDocx />
            </li>
            <li>
              <a role="button" onClick={() => this.setState((s) => ({ ...s, showModalVideos: true }))}>
                <span className="icon mdi mdi-collection-video" /> {i18n.t('Tutoriais em Vídeo')}
              </a>
            </li>
            <li>
              <Link to={URLS.advisoryProducts}>
                <span className="icon mdi mdi-accounts-outline" /> {i18n.t('Solicitar assessoria acadêmica')}
              </Link>
            </li>
            <li>
              <a href="https://go.hotmart.com/B41835074L" target="_blank">
                <table>
                  <tbody>
                    <tr>
                      <td valign="top">
                        <span className="icon mdi mdi-slideshow" />
                      </td>
                      <td valign="top">
                        Curso Planejamento e elaboração de
                        <br />
                        Projeto de Pesquisa
                      </td>
                    </tr>
                  </tbody>
                </table>
              </a>
            </li>
          </ul>
        </li>

        <Modal
          title={i18n.t('Tutoriais em Vídeo')}
          show={this.state.showModalVideos}
          customWidth="55vw"
          onCancel={() => this.setState((s) => ({ ...s, showModalVideos: false }))}
        >
          <Videos />
        </Modal>
      </React.Fragment>
    );
  }
}

HelpButton.propTypes = {
  document: PropTypes.object.isRequired,
};

export default withNamespaces()(HelpButton);
