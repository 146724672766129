import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import Ajax from '../../common/ajax';
import NavbarHeader from '../../common/components/navbar/NavbarHeader';
import NavbarUserMenu from '../../common/components/navbar/NavbarUserMenu';
import WaitDownloadLink from '../../common/components/WaitDownloadLink';
import HtmlUtils from '../../common/htmlutils';
import Config from '../../config';
import { AppContext, hasEbook, isSuperuser } from '../../context/global';
import CopyDocumentButton from '../../documents/components/CopyDocumentButton';
import ShareButton from '../../documents/components/ShareButton';
import Statistics from '../../documents/components/Statistics';
import PlanExpiration from '../../sales/billing/PlanExpirationButton';
import URLS from '../../urls';
import ChangeTemplate from './ChangeTemplate';
import DownloadButton from './DownloadButton';
import Events from './Events';
import HelpButton from './HelpButton';
import HistoryModal from './HistoryModal';
import Identification from './identification/Identification';
import ImportFromWordButton from './ImportFromWordButton';
import LockIndicatorPolling from './lock/LockIndicatorPolling';
import Models from './Models';
import ModelsAdd from './ModelsAdd';
import Options from './options/Options';
import ProofreaderPitch from './ProofreaderPitch';
import SaveButton from './SaveButton';
import ExportToDocx from '../../documents/components/ExportToDocx';
import FirstStepsVideo from './FirstStepsVideo';

class EditorNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
    };
    this.fetchDocumentTitle = this.fetchDocumentTitle.bind(this);
  }

  componentDidMount() {
    this.props.editor.on(Events.AFTER_IDENTIFICATION_SAVE, () => {
      this.fetchDocumentTitle();
    });
  }

  fetchDocumentTitle() {
    const url = `${Config.apiHost}documents/${this.props.document.id}/`;
    Ajax.get(url, 'title').done((data) => {
      this.setState((state) => ({ ...state, title: data.title }));
    });
  }

  getAdminMenu() {
    if (!isSuperuser()) {
      return null;
    }
    return (
      <React.Fragment>
        <li className="divider" />
        <li>
          <CopyDocumentButton
            document={this.props.document}
            onCopy={(newDocId) => (document.location = URLS.documentEdit(newDocId))}
          />
        </li>
      </React.Fragment>
    );
  }

  renderNavbarRight() {
    const { i18n } = this.props;

    return (
      <ul className="nav navbar-nav navbar-right be-icons-nav" style={{ marginRight: '0px' }}>
        <li className="dropdown">
          <SaveButton
            attribute={this.props.attribute}
            isCustomAttribute={this.props.isCustomAttribute}
            editor={this.props.editor}
            document={this.props.document}
          />
        </li>

        <ProofreaderPitch document={this.props.document} editor={this.props.editor} />

        <DownloadButton document={this.props.document} editor={this.props.editor} />

        <li id="properties-menu-item" className="dropdown hidden-xs">
          <Options document={this.props.document} editor={this.props.editor} />
        </li>

        <li
          id="import-word-toolbar"
          className="hidden-xs dropdown"
          title={i18n.t('Importar texto de um arquivo Word')}
          data-toggle="tooltip"
          data-placement="bottom"
        >
          <ImportFromWordButton document={this.props.document} editor={this.props.editor} />
        </li>

        <li className="dropdown hidden-xs">
          <HistoryModal documentId={this.props.document.id} />
        </li>

        <li
          className="hidden-xs dropdown"
          title={i18n.t('Compartilhar o documento com outras pessoas')}
          data-toggle="tooltip"
          data-placement="bottom"
        >
          <ShareButton document={this.props.document} showLabel={false} />
        </li>

        <li id="first-steps-video" className="dropdown">
          <FirstStepsVideo document={this.props.document} />
        </li>

        <HelpButton document={this.props.document} />

        <li id="id-lock-indicator" className="dropdown hidden-xs">
          <LockIndicatorPolling document={this.props.document} editor={this.props.editor} />
        </li>

        {!Config.isBrandEnabled && (
          <li className="hidden-xs hidden-sm">
            <p className="navbar-btn" style={{ paddingTop: '3px' }}>
              <PlanExpiration />
            </p>
          </li>
        )}

        <li
          id="id-document-menu"
          className="dropdown hidden-xs"
          title={i18n.t('Mais opções')}
          data-toggle="tooltip"
          data-placement="bottom"
        >
          <a data-toggle="dropdown" role="button" aria-expanded="false" className="dropdown-toggle">
            <span className="icon mdi mdi-more-vert" />
          </a>
          <ul className="dropdown-menu">
            <li>
              <Statistics documentId={this.props.document.id} />
            </li>
            <li>
              <ImportFromWordButton document={this.props.document} editor={this.props.editor} forceShow={true} />
            </li>
            <li>
              <ExportToDocx />
            </li>
            <li>
              <ChangeTemplate document={this.props.document} editor={this.props.editor} />
            </li>

            {!this.props.document.is_presentation && (
              <>
                <li className="divider" />
                <li>
                  <ChangeTemplate
                    document={this.props.document}
                    editor={this.props.editor}
                    isCreatePresentation={true}
                  />
                </li>
              </>
            )}

            <li className="divider" />
            <li>
              <Models document={this.props.document} editor={this.props.editor} />
            </li>
            <li>
              <ModelsAdd document={this.props.document} editor={this.props.editor} />
            </li>
            {(this.context.has_valid_paid_plan || isSuperuser()) && (
              <li>
                <WaitDownloadLink
                  buttonTitle={i18n.t('Exportar para LaTeX')}
                  buttonIcon="icon mdi mdi-download"
                  href={`${Config.apiHost}documents/${this.props.document.id}/download_latex/`}
                />
              </li>
            )}

            {(hasEbook() || isSuperuser()) && (
              <li>
                <WaitDownloadLink
                  buttonTitle={i18n.t('Exportar para E-book')}
                  buttonIcon="icon fas fa-book"
                  href={`${Config.apiHost}documents/${this.props.document.id}/download_epub/`}
                />
              </li>
            )}

            {this.getAdminMenu()}
          </ul>
        </li>
      </ul>
    );
  }

  render() {
    const { i18n } = this.props;

    let nav;

    if (!this.props.document || !this.props.editor) {
      nav = (
        <ul className="nav navbar-nav navbar-right be-icons-nav">
          <li className="dropdown">
            <a>...</a>
          </li>
        </ul>
      );
    } else {
      const title = HtmlUtils.stripHtmlTags(
        this.state.title || this.props.document.title || i18n.t('Documento sem título'),
      );
      nav = (
        <React.Fragment>
          <NavbarHeader document={this.props.document} />
          <div className="be-right-navbar">
            <div
              className="page-title"
              title={i18n.t('Editar o título e outras informações')}
              style={{ maxWidth: '30%' }}
              data-toggle="tooltip"
              data-placement="bottom"
            >
              <Identification document={this.props.document} editor={this.props.editor}>
                {this.props.document.is_presentation ? (
                  <span
                    className="icon mdi mdi-slideshow"
                    title={i18n.t('Documento')}
                    style={{ fontSize: '20pt', verticalAlign: 'middle', paddingBottom: '2px' }}
                  />
                ) : (
                  <span
                    className="far fa-file"
                    title="Slides"
                    style={{ fontSize: '15pt', verticalAlign: 'middle', paddingBottom: '2px' }}
                  />
                )}{' '}
                {title}
              </Identification>
            </div>
            <NavbarUserMenu document={this.props.document} />
            {this.renderNavbarRight()}
          </div>
        </React.Fragment>
      );
    }

    return (
      <nav className="navbar navbar-default navbar-fixed-top be-top-header ">
        <div className="container-fluid">{nav}</div>
      </nav>
    );
  }
}

EditorNavbar.contextType = AppContext;

EditorNavbar.propTypes = {
  /**
   * Document instance.
   */
  document: PropTypes.object.isRequired,
  /**
   * Instance of the CKEditor.
   */
  editor: PropTypes.object.isRequired,
  attribute: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isCustomAttribute: PropTypes.bool.isRequired,
};

export default withNamespaces()(EditorNavbar);
